const initial_state = {
    userData: [],
    planData: [],
    subscribeData:[],
    isActive:false,
    isLoggedIn:false,
    register:false,
    plan:[],
    tokenData:"",
    shopName:"",
    shopId:"",
    orderDetail:{Result:[]},
    orderData:{Result:[]},
    appsettingData:[],
    shopaccountdetailsData:[],
    schedule_date:""
}

export default function facebookReducer(state = initial_state, action) {
    console.log("==================",action);
    switch (action.type) {
        case "LOGIN": {
            return {
                ...state,
            //    userData:action.data,
                isLoggedIn:true

            }
        }
        case "LOGOUT": {
            return {
                ...state,
                userData: [],
                planData: [],

                isLoggedIn:false

            }
        }
        case "SETTOKEN": {
            return {
                ...state,
                tokenData: action.data,
            

                isLoggedIn:false

            }
        }
        case "SHOPID": {
            return {
                ...state,
                shopId: action.data,
            

                isLoggedIn:false

            }
        }
        case "SHOPNAME": {
            return {
                ...state,
                shopName: action.data,
            

                isLoggedIn:false

            }
        }
        case "SETORDER": {
            return {
                ...state,
                orderDetail: action.data,

            }
        }
        case "SETPLAN": {
            return {
                ...state,
         plan:action.data,

            }
        }
        case "COMMENT_DATA": {
            return {
                ...state,
                comments: action.payload,
                // loader:

            }
        }
        case "REGISTER": {
            return {
                ...state,
                register: true,
                // loader:

            }
        }
        case "ORDERDATA": {
            return {
                ...state,
                orderData: action.data,
         
        
            }
        }
        case "CLEAR_COMMENT": {
            return {
                ...state,
                comments: [],
                // loader:

            }
            
        }
        case "CLEAR_REG": {
            return {
                ...state,
                register: false,
                // loader:

            }
            
        }

        case "USERPLAN": {
            return {
                ...state,
                planData:action.data
                // loader:

            }
            
        }

        /* case "GET_STORE_DETAILS": {
            return {
                ...state,
                getStoreDetails:action.data
                // loader:

            }
            
        } */

        case "SUBSCRIBE": {
            return {
                ...state,
                subscribeData:action.data
                // loader:

            }
            
        }
        case "APPSETTING": {
            return {
                ...state,
                appsettingData:action.data
                // loader:

            }
            
        }

        case "SHOPACCOUNTDETAILS": {
            return {
                ...state,
                shopaccountdetailsData:action.data
                // loader:

            }
            
        }

        case "UNSUBSCRIBE": {
            return {
                ...state,
                subscribeData:action.data
                // loader:

            }
            
        }

        case "SCHEDULEDATE": {
            return {
                ...state,
                schedule_date:action.data
                // loader:

            }
            
        }
  
        default:
            return initial_state
    }
}