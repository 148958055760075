import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button} from '@material-ui/core';
import Footer from "../Components/CommonComponents/Footer"
import { useDispatch, useSelector } from 'react-redux';
import { appSettingData, sendTime } from '../Store/Actions/auth'
import { toast } from 'react-toastify';
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },

  },
  maxWidth450: {
    maxwidth: '450px',
  },

  sift1: {
    marginLeft: '80px',
    [theme.breakpoints.down("sm", "md")]: {
      marginLeft: '0px',
    },
  },
  sift2: {
    maxWidth: '600px',
    marginLeft: '20px'
  },
  margin: {
    margin: '0px'
  },
  sift3: {
    maxWidth: '450px',
    marginLeft: '70px',
    [theme.breakpoints.down("sm", "md")]: {
      marginLeft: '0px',
    },

  },
  perorder: {
    position: 'relative', fontWeight: '600', minWidth: '70px', marginLeft: '10px',
    [theme.breakpoints.down("sm", "md")]: {
      marginLeft: '5px'
    },
  },
  mr30: {
    marginRight: '30px',
    [theme.breakpoints.down("sm", "md")]: {
      marginRight: '10px',
    },
  },
  mr20: {
    marginRight: '20px',
    [theme.breakpoints.down("sm", "md")]: {
      marginRight: '0px',
      position: 'relative',
      left: '-5px'
    },
  },
  alertbox: {
    marginBottom: '40px',
    maxWidth: '515px'

  },
  set_time_label:{
      position: 'relative',
      top: '11px',
      marginRight: '454px',
      fontWeight: 'bold',
      background: '#fff'
  },

}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
      fontWeight: 'bold'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#84C5A3',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.2)',
      },
      '&:hover fieldset': {
        borderColor: '#84C5A3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#84C5A3',
      },
    },
  },


})(TextField);

export default function MultilineTextFields() {
  const classes = useStyles();
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(appSettingData())
  }, [])

  const getTime = useSelector((state) => state.auth.appsettingData);
  console.log("hello state", getTime);
  const [time1, setTime] = useState("")
  useEffect(() => {
    if (getTime.length > 0) {
      setTime(getTime[0].time)
    }
  }, [getTime])

  const handleChange = (event) => {
      setTime(event?.target?.value);
  };

  const onSubmitAppSetting = () => {
    if (time1 < 1) {
      toast.error("Value must be grater than 0")

    } else if (time1 > 6) {
      toast.error("Value must be less than 6")

    } else {
      setTime(time1)
      dispatch(sendTime(time1))
    }
  }

  return (<>

    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.sift1} >
        <div style={{ marginTop: "5%", marginBottom: "10%" }} className={classes.sift2}>
          <Grid container spacing={3} >
            <Grid item xs={12} style={{ paddingLeft: '0' }}>
              <label className={classes.set_time_label}>DELIVERY TIME</label>
              <div style={{ display: 'flex'}}>
                <input 
                  style={{ border: "2px solid #79c4a4",  width: "100%",  margin: "0px;", padding: "19px",  borderRadius: "6px" }}
                  type="number"
                  value={time1}
                  onChange={handleChange}
                  name="order_time_setting"
                  required="required"
                />
                <Button
                  style={{
                    padding: "16px 30px",
                    backgroundColor: "#84C5A3",
                    marginLeft: "10px",
                  }}
                  onClick={onSubmitAppSetting}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </form>
    <Footer />
  </>
  );
}
