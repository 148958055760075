import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Home from "../Pages/HomePage";
import Subscribe from "../Pages/SubscriptionPage";

import Account from "../Pages/AccountPage";
import AccountSetting from "../Pages/AppSetting";
import ManageOrder from "../Pages/OrderListPage";
import AddProduct from "../Pages/CustomerAddProduct";
import ShoppingDetails from "../Pages/CostumerShoppingDetails";
import { getPlanSubRequest } from "../Lib/Service/api";
// import Login from "../Pages/LoginPage";
// import Register from "../Pages/RegistrationPage";

// import Dashboard from "./dashboard";
// import ListPage from "./listPage";
// import AddProduct from "./addProduct";
// import CustomerDetails from "./customerDetails";
// import Header from "../Components/header"
// import Sidebar from "../Components/CommonComponents/sidebar-header"
import Footer from "../Components/CommonComponents/Footer"

import { useSelector } from 'react-redux'


function Layout(props) {
	// const { isLoggedIn, logout } = props;
	const classes = ""
	// useStyles();
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

	const [planCheckStatus, setPlanCheckStatus] = useState([]);
	
	useEffect(() => {
		toCheckPlanSubRequest();
	},[]);

	const toCheckPlanSubRequest = async () => {
		const listPlanSubRequest = await getPlanSubRequest();
		setPlanCheckStatus(listPlanSubRequest);
		
	};

	console.log(">>>>>>>>>>>>>>>>>>>>>>", planCheckStatus);

	return (
		
		<div className={classes.root}>
			{/* <div>{isLoggedIn ? <Sidebar  /> : ""}</div> */}
			<div>
				{isLoggedIn ? (

					<div>

						{/* <Sidebar /> */}
					</div>
				) : (
					""
				)}
				<Switch>
					<div style={{ marginTop: "5%" }}>
						<PrivateRoute
							path={"/dashboard"}
							isLogin={isLoggedIn}
							component={<Home />}
						/>

						<PrivateRoute
							path={"/manage-order"}
							isLogin={isLoggedIn}
							component={<ManageOrder />}
						/>
						<PrivateRoute
							path={"/account"}
							isLogin={isLoggedIn}
							component={<Account />}
						/>
						<PrivateRoute
							path={"/account-setting"}
							isLogin={isLoggedIn}
							component={<AccountSetting />}
						/>

						{/* <PrivateRoute
							path={"/shopping-details"}
							isLogin={isLoggedIn}
							component={<ShoppingDetails />}
						/> */}

						{/* <PrivateRoute
							path={"/adding-product"}
							isLogin={isLoggedIn}
							component={<AddProduct />}
						/> */}
						
						<PrivateRoute
							path={"/subscribe"}
							isLogin={isLoggedIn}
							component={<Subscribe />}
						/>
						{/* <PublicRoute
						path={"/login"}
						isLogin={isLoggedIn}
						component={<Login />}
					/>
					<PublicRoute
						path={"/register"}
						isLogin={isLoggedIn}
						component={<Register />}
					/> */}



						{/* Need to redict on valid route if user loggedIn or not*/}
						{
							planCheckStatus.status ? (
								<Route render={() => <Redirect to="/manage-order" />} />
							) : (
								<Route render={() => <Redirect to="/dashboard" />} />
							)
						}
						
					</div>
				</Switch>
				{/* {isLoggedIn ? (

<div>
				
						<Footer />
					</div>
				) : (
					""
				)} */}
			</div>
		</div>
	);
}
// const mapStateToProps = ({ auth }) => {
// 	const { isLoggedIn } = auth;
// 	return {
// 		isLoggedIn,
// 	};
// };
// const mapDispatchToProps = (dispatch) => ({
// 	logout: () => dispatch(logoutUser()),
// });

export default Layout;

