import logo from './logo.svg';
import './App.css';
import Layout from './Components/CommonComponents/SideBar'
// import {userLogin}from './store/actions/auth'
import { useDispatch } from 'react-redux'

import { BrowserRouter as Router } from 'react-router-dom';
import { userLoginAppData, setToken, setShopID, setShopName } from "../src/Store/Actions/auth"
import { useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userPlan, setLogin } from "./Store/Actions/auth"
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

function App() {

  const history = useHistory();
  const dispatch = useDispatch();
  const data = localStorage.getItem('user')

  useEffect(() => {
    dispatch(setLogin())
    dispatch(userPlan(data))
    // setResponse(planData);
  });
  const checkURL = window.location.href
  const url21 = checkURL.split("/");
  console.log(url21);

  if (url21[3] === "dashboard") {
    console.log("dashboard");
    console.log(url21.length);
    if (url21.length === 8) {
      const str = window.location.href;
      const url1 = str.split("/");
      console.log(url1[4], url1[5], url1[6]);
      //  const CheckUrl= JSON.stringify(url1[4]).split("%");

      // if(CheckUrl){
      //   console.log("pass");
      // }
      dispatch(setToken(url1[4]))
      dispatch(setShopID(url1[5]))
      dispatch(setShopName(url1[6]))
      const localData = localStorage.getItem('user');

      if (localStorage.getItem('setToken') !== "undefined" || localStorage.getItem('setToken') !== undefined) {
        localStorage.setItem('setToken', JSON.stringify(url1[4]));
        localStorage.setItem('ShopID', JSON.stringify(url1[5]));
        localStorage.setItem('setShopName', JSON.stringify(url1[6]));
      }

    }
  }
  if (localStorage.getItem('user') !== null) {
    // console.log(`Email address exists`);
    dispatch(userLoginAppData(localStorage.getItem('user')))
    
  } else {
    // console.log(`Email address not found`);
  }

  const tokenData = useSelector((state) => state.auth.tokenData)

  useEffect(() => {
    if (tokenData !== "") {
      toast.success("App installed successfully");
      //localStorage.removeItem('subscribe');
      // history.push("/register");
    }
  }, [tokenData])


  return (
    <div className="App">
      <ToastContainer position="top-center" autoClose={2500} />
      <Router>
        <Layout />
      </Router>
    </div>
  );
}

export default App;
