import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Card,
  Typography,
  Button,
  Modal,
  CardContent,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Footer from "../Components/CommonComponents/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderData,
  pushOrder,
  orderDetailAciton,
} from "../Store/Actions/auth";
import Alert from "@material-ui/lab/Alert";
import CardActions from "@material-ui/core/CardActions";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import PublishIcon from "@material-ui/icons/Publish";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { getOrderApiDetails, pushOrderApi } from "../Lib/Service/api";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import moment from 'moment';
import { toast } from 'react-toastify'; 

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "324px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    maxHeight: 440,
  },
  heading: {
    padding: "15px 25px",
    borderBottom: "1px solid rgba(0,0,0,.1)",
    display: "block",
    width: "100%",
    textAlign: "left",
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 30,
  },
  alertbox: {
    marginBottom: "40px",
  },
}));

const useStyles1 = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [active, setActive] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const orderDetail = useSelector((state) => state.auth.orderDetail.Result);
  const [date, setDate] = useState(new Date());
  const [filterVal, setFilterVal] = React.useState('');
  const [createActive, setCreateActive] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    orderListDetailsApi();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlepushOrder = async (id) => {
    setCreateActive(true);
    const response = await pushOrderApi(id);
    if(response.status){
      setCreateActive(false);
      orderListDetailsApi();
			toast.success("Order Initiated");
		}
		else{
      setCreateActive(false);
      orderListDetailsApi();
			toast.error(response.Message);
		}
  };

  const handleviewDetails = (id) => {
    setOpen(true);
    console.log("check");
    dispatch(orderDetailAciton(id));
  };

  const iconChange = (status, id) => {
    if (status === 2) {
      return (
        <Tooltip disableFocusListener title="View Details">
          <FormatAlignJustifyIcon
            onClick={() => {
              handleviewDetails(id);
            }}
            style={{ color: "gray" }}
          />
        </Tooltip>
      );
    } else {
      return ( 
        <>
           {
              createActive ? (
                <CircularProgress />
              ) : (
                <Tooltip disableFocusListener title="Submit This Order to GOFR">
                  <PublishIcon
                    onClick={() => {
                      handlepushOrder(id);
                    }}
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              ) 
            }
        </>
      );
    }
  };

  const statusChange = (status) => {
    if (status === 2) {
      return " Job created";
    } else {
      return "Job pending";
    }
  };

  const openLink = (a) => {
    window.open(a);
  };

  const orderListDetailsApi = async (new_date) => {
    setActive(true);
    const listOrderDetails = await getOrderApiDetails(new_date);
    if(listOrderDetails?.status === true){
      setData(listOrderDetails?.Result);
      setActive(false);
    }else{
      setData([]);
      setActive(false);
    }
    console.log(">>>>>>>", listOrderDetails?.Result); 
  };

  const changeDate = (date) => {
    setDate(date);
    setData([]);
    var d = new Date(date);
    var new_date = getFormattedString(d);
    setFilterVal(new_date);
    orderListDetailsApi(new_date);
  };

  function getFormattedString(d) {
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  }

  console.log("--------------", date);

  return (
    <>
      <div className={classes.alertbox}>
        <Alert severity="info">
          This page helps to view all the orders and initiate the pending ones.
        </Alert>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{
            margin: "1%",
            marginBottom: "25px",
            marginLeft: "0",
            textAlign: "left",
            padding: "0px 0px 25px 0px",
          }}>
            <h2>Select date to see scheduled orders</h2>
            { filterVal ? ( filterVal==='NaN-NaN-NaN' ? ('') : (<span style={{marginBottom:"10px"}}>
             Search result for this date <strong>{filterVal}</strong>
            </span>) ) : ('')}
          </div>
        
        <div  style={{
                margin: "0%",
                marginBottom: "25px",
                marginLeft: "0",
                padding: "0px 0px 25px 0px",
              }}>
          <form style={{display:"flex", flexDirection: "column", justifyContent: "space-around"}}>
            <span style={{marginBottom:"10px"}}>
             Filter out your "Job created" orders
            </span>
            <input
              style={{padding: "20px",
                border: "2px solid #000",
                background: "#fbfcfd",}}
              className="form-control"
              type="date"
              name="datepic"
              placeholder="DateRange"
              value={date}
              data-date-end-date="0d"
              onChange={(e) => changeDate(e.target.value)}
            />
          </form>
        </div>
      </div>

      <div style={{ marginBottom: "-15%" }}>
        <div>
          <Paper className={classes.root}>
            <Typography
              className={classes.heading}
              style={{ marginBottom: "0" }}
            >
              Latest Orders
            </Typography>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>

                    <TableCell>Customer</TableCell>

                    <TableCell>Date</TableCell>

                    <TableCell>Status</TableCell>

                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {active ? (
                  <TableRow>
                    <TableCell colSpan={5} style={{ textAlign: "center" }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableBody>
                    {data?.length > 0 ? (
                      data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              <TableCell>{row?.order_id}</TableCell>

                              <TableCell>{row?.shipping_name}</TableCell>

                              <TableCell>{moment(row?.order_created_at).format('YYYY-MM-DD')}</TableCell>

                              <TableCell>
                                <React.Fragment style={{ marginLeft: "10%" }}>
                                  {statusChange(row?.status)}
                                </React.Fragment>
                              </TableCell>

                              <TableCell>
                                  <React.Fragment style={{ marginLeft: "10%" }}>
                                    {iconChange(row?.status, row?.order_id)}
                                  </React.Fragment>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={"5"}>Record not found!</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>

        <Footer />
        <br />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          // className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Card
              className={classes1.root}
              style={{ width: "50%", marginLeft: "25%", marginTop: "20%" }}
            >
              {orderDetail.length ? (
                <div>
                  <CardActions
                    style={{ textAlign: "right", display: "flow-root" }}
                  >
                    <CloseIcon onClick={handleClose} />
                  </CardActions>

                  <CardContent>
                    <Typography component="h2" style={{ marginBottom: "10px" }}>
                      <b> Name:</b> {orderDetail[0].job_pickup_name}
                    </Typography>

                    <Typography component="h2" style={{ marginBottom: "10px" }}>
                      <b>Address:</b> {orderDetail[0].job_pickup_address}
                    </Typography>

                    <Typography component="h2" style={{ marginBottom: "10px" }}>
                      <b> Message:</b> {orderDetail[0].message}
                    </Typography>

                    <Typography component="h2">
                      <b> Track Order:</b>{" "}
                      <Button
                        style={{
                          backgroundColor: "#84C5A3",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          openLink(orderDetail[0].pickup_tracking_link);
                        }}
                      >
                        {" "}
                        Track
                      </Button>
                    </Typography>
                  </CardContent>
                </div>
              ) : (
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableRow>
                      <TableCell colSpan={5} style={{ textAlign: "center" }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              )}
            </Card>
          </Fade>
        </Modal>
      </div>
    </>
  );
}
