import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Card, CardContent, Typography, CardActions, Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux'
import { planSubscribe } from '../Store/Actions/auth'
import { userPlan, planUnsubscribe, sendChargeId } from "../Store/Actions/auth"
import CheckIcon from '@material-ui/icons/Check';
import Alert from '@material-ui/lab/Alert';
import { getPlanSubRequest } from "../Lib/Service/api";

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 350,
    width: 250,
  },
  control: {
    padding: theme.spacing(2),
  },

  alertbox: {
    marginBottom: '40px'
  }
}));


export default function SpacingGrid() {
  // const dispatch = useDispatch();
  // const redirect = useSelector((state) => state.auth.subscribeData)
  // const [spacing, setSpacing] = React.useState(2);
  // const classes = useStyles();
  // const planData = useSelector((state) => state.auth.planData.Result)
  // const subPlan = parseInt(useSelector((state) => state.auth.plan.plan_id))
  const history = useHistory();
  // if(window.location.href!=="http://localhost:3000/dashboard"){
  //   // setUrl(window.location.href);
  //   const sub=JSON.parse(localStorage.getItem('subscribe'))

  //   const str=window.location.href;
  //   const url1= str.split("=");

  // }

  //   const handleChange = (event) => {
  //     setSpacing(Number(event.target.value));
  //   };

  //   const [response,setResponse]=useState([])

  //   const [isRender,setIsrender]=useState("")
  //   // 
  //   const data = localStorage.getItem('user') 
  const [planCheckStatus, setPlanCheckStatus] = useState();
  const [active, setActive] = useState(true);
  const [activeStatus, setActiveStatus] = useState(true);

  useEffect(() => {
    toCheckPlanSubRequest();
  },[]);

  const toCheckPlanSubRequest = async () => {
    const listPlanSubRequest = await getPlanSubRequest();
    setActive(true);
    setPlanCheckStatus(listPlanSubRequest);
  };

  // const handleClick=(a)=>{
  //   dispatch(planSubscribe(a))
  // }
  const handleSubscribe = () => {
    history.push("/subscribe")
  }
  // const handleUnsubscribe=(a)=>{
  //   dispatch(planUnsubscribe(a))
  // }
  // console.log(subPlan);
  // let planCheck = (data) => {
  //   if (data.planId===subPlan){
  //     return (<Button style={{backgroundColor:"#E1C16E",marginTop:"4%",fontWeight:"bold"}} onClick={()=>handleUnsubscribe(data)}>Unsubscribe</Button>)
  //   }
  //   else {
  //     return (<Button style={{backgroundColor:"#84C5A3",marginTop:"4%",fontWeight:"bold"}} onClick={()=>handleClick(data)}>Subscribe</Button>)
  //   }
  // }
  // useEffect(() => {
  //   dispatch(userPlan(data))
  // },[]);
  // useEffect(() => {
  //   setResponse(planData);
  // });
  // useEffect(()=>{
  // if(redirect.status){
  //   window.location.href = redirect.redirect_url;
  // }
  // },[redirect])

  
  const Sub = JSON.parse(localStorage.getItem('subscribe'))
  const classes = useStyles();

  let planCheck = () => {
    console.log(planCheckStatus?.status);
    if (planCheckStatus?.status === true) {
      if (planCheckStatus?.Result[0].plan_id === 1) {
        return (
          <div className={classes.alertbox}> <Alert severity="info">Congrats, you Subscribed to STARTER Plan. Click on Upgrade Plan Button to upgrade.</Alert></div>
        )
      }
      if (planCheckStatus?.Result[0].plan_id === 2) {
        return (
          <div className={classes.alertbox}> <Alert severity="info">Congrats, you Subscribed to PROFESSIONAL Plan. Click on Upgrade Plan Button to upgrade.</Alert></div>
        )

      }
      if (planCheckStatus?.Result[0].plan_id === 3) {

        return (<div className={classes.alertbox}> <Alert severity="info">Congrats, you Subscribed to E-COMMERCE Plan. Click on Upgrade Plan Button to upgrade.</Alert></div>)
      }
    }
    // if (Sub != null) {
    //   if (Sub.data.plan_id === "1") {
    //     return (
    //       <div className={classes.alertbox}> <Alert severity="info">Congrats, you Subscribed to STARTER Plan. Click on Upgrade Plan Button to upgrade.</Alert></div>
    //     )
    //   }
    //   if (Sub.data.plan_id === "2") {
    //     return (
    //       <div className={classes.alertbox}> <Alert severity="info">Congrats, you Subscribed to PROFESSIONAL Plan. Click on Upgrade Plan Button to upgrade.</Alert></div>
    //     )

    //   }
    //   if (Sub.data.plan_id === "3") {

    //     return (<div className={classes.alertbox}> <Alert severity="info">Congrats, you Subscribed to E-COMMERCE Plan. Click on Upgrade Plan Button to upgrade.</Alert></div>)
    //   }
    // }
  }

  // useEffect(() => {
  //   if (Sub !== null) {
  //     console.log("setactive");
  //     setActive(false)
  //   }
  // }, [Sub])

  useEffect(() => {
    if (planCheckStatus?.status === true) {
      console.log("setactive");
      setActive(false);
    }
  }, [planCheckStatus])

  return (
    <>
    <h1>Setup Instructions</h1>
    <div style={{ margin: "auto", maxWidth: "700px", textAlign:'initial', background:'#84c5a329', padding:'20px 0px', marginBottom:'30px'}}>
      <ul style={{listStyle:'none'}}>
        <li>Step - 1 : First of all, go to <a href='https://apps.shopify.com/' target='_blank'>Shopify App Store URL</a></li>
        <li>Step - 2 : Now search for "GOFR Courier App"</li>
        <li>Step - 3 : Click on Add App</li>
        <li>Step - 4 : Now log in with your valid Shopify credentials</li>
        <li>Step - 5 : Click on the Install button</li>
        <li>Step - 6 : Now, your app will get installed</li>
        <li>Step - 7 : Go to Manage Orders, once a new order is received by your Shopify store</li> 
        <li>Step - 8 : Click on the "Submit This Order to GOFR" button under Action, so it can be pushed and created by our dispatch system</li>  
      </ul>
    </div>
    
    <div style={{ margin: "auto", maxWidth: "700px" }}>
      {active ? (
         <div>
       <div className={classes.alertbox}> <Alert severity="info">Congrats, you have successfully installed our app. Please click on Subscribe Plan button to subscribe to our plans.</Alert></div>
       <Button style={{ backgroundColor: "#84C5A3", marginTop: "13px" }} onClick={handleSubscribe}>Subscribe Plan</Button>
     </div>
      ) : (
        <div>

          <React.Fragment style={{ marginLeft: "10%" }}>{planCheck()}</React.Fragment>
          
          <Button style={{ backgroundColor: "#84C5A3", marginTop: "13px" }} onClick={handleSubscribe}>Upgrade Plan</Button>

        </div>
      )}

    </div>
    </>
    );
}
