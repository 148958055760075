import axios from 'axios'
var qs = require('qs');

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.withCredentials = true
// const SCRAPING_URL="http://localhost:3000/"
// This is localhost - const BaseURL = "https://euromaxfood.devtrust.biz/public/api/"

const BaseURL = "https://api.gofrcourier-ecommerce.com/public/api/";
//for Facebook



// export const registerNewUser = (a) => {    
//     console.log("api call");
//     console.log(a);  
//     // const data = JSON.stringify({
//     //     "firstName":a['firstName'],
//     //     "lastName":a['lastName'],
//     //     "email":a['email'],
//     //     "contact":a['contact'],
//     //     "password":a['password'],
//     //     "middleName":'',
//     // });
//     var data = JSON.stringify({
//         'firstName': 'Rupesh',
//         'middleName': '',
//         'lastName': 'Singh',
//         'email': 'singh.rupesh282@gmail.com',
//         'contact': '9044318730',
//         'password': '123456' 
//       });

//     console.log(data);
//     let headers = {
//         'Content-Type': 'application/x-www-form-urlencoded'
//     }
//     // headers: { 

//     //   },
//     // console.log(headers);
//     return axios.post(
//         SCRAPING_URL+'adduser',  { headers },data
//     ).then(res => {
//                console.log("res", res.data);
//                return res.data
//     })
//         .catch(err => console.log(err))
// }


export const registerNewUser = (a) => {
  console.log("api call", a);
  var data = qs.stringify({
    'firstName': a['firstName'],
    'middleName': '',
    'lastName': a['lastName'],
    'email': a['email'],
    'contact': a['contact'],
    'password': a['password'],
    'confirmPassword': a['confirmPassword']

  });
  var config = {
    method: 'post',
    url: BaseURL+'adduser',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    });
}
export const loginUser = (a) => {
  console.log(a);
  var data = qs.stringify({
    'email': a['user_name'],
    'password': a['password'],
  });
  console.log(data);
  var config = {
    method: 'post',
    url: BaseURL+'login',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {

      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}

export const userPlanApi = (a) => {

  const obj = JSON.parse(a)

  var data = qs.stringify({
    'token': JSON.parse(localStorage.getItem('setToken'))
  });
  var config = {
    method: 'post',
    url: BaseURL+'planmaster',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export const unsubscribePlan = (data) => {
  /* var data = qs.stringify({
    'userid': data['user_id'],
    'planId': data['planId'],
    'token': data['Token']
  }); */
  var data = qs.stringify({
    'shop_id': JSON.parse(localStorage.getItem('ShopID')),
    'planId': data['planId'],
    'token': data['Token'],
  });
  var config = {
    method: 'post',
    //url: BaseURL+'planunscbscribe',
    url: BaseURL+'todeleterecurring',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      console.log(">>>>>>>>>>>>", response);
      localStorage.removeItem('subscribe');
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export const subscribePlan = (data) => {
  console.log("sub api", data);
  var data = qs.stringify({
    'shop_id': JSON.parse(localStorage.getItem('ShopID')),
    'planId': data['planId'],
    'token': JSON.parse(localStorage.getItem('setToken')),
    'plan_status': 'subscribed'
  });
  var config = {
    method: 'post',
    url: BaseURL+'createrecurring',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}
export const getOrderApi = () => {
  var data = qs.stringify({
    'shop_id': JSON.parse(localStorage.getItem('ShopID')),
    'token': JSON.parse(localStorage.getItem('setToken')),
    'shop_name': JSON.parse(localStorage.getItem('setShopName').replaceAll("%20", "-")),
    'scheduled_date' : (localStorage.getItem('to_check_scheduled_orders_date')) ? localStorage.getItem('to_check_scheduled_orders_date') : '',
  });
  console.log("getOrder", data);

  var config = {
    method: 'post',
    url: BaseURL+'showorders',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
     // console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}
export const sendChargeApi = (a, plan_id) => {
  console.log("logout data", a, plan_id);
  var data = qs.stringify({
    "charge_id": a,
    "planId": plan_id

  });
  console.log("logout", data);

  var config = {
    method: 'post',
    url: BaseURL+'recurringresponse',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}

export const logoutUser = (a) => {
  const obj = JSON.parse(a)
  console.log(obj['user_id']);
  var data = qs.stringify({
    'userid': obj['user_id'],
  });
  console.log("logout", data);

  var config = {
    method: 'post',
    url: BaseURL+'logout',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}
export const pushOrderApi = (a) => {

  var data = qs.stringify({
    'order_id': a,
  });

  var config = {
    method: 'post',
    url: BaseURL+'createtookanjobs ',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      //console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}
export const orderDetailsApi = (a) => {
  console.log(a);
  var data = qs.stringify({
    'order_id': a,
  });

  var config = {
    method: 'post',
    url: BaseURL+'tookanjobdetails  ',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}

export const getordertimesettingsApi = (a) => {
  console.log(a);
  var data = qs.stringify({
    'ShopID': JSON.parse(localStorage.getItem('ShopID')),
  });

  var config = {
    method: 'post',
    url: BaseURL+'getordertimeDetails  ',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}

export const sendTimeApi = (a) => {
  
  console.log(a);
  var data = qs.stringify({
    'ShopID': JSON.parse(localStorage.getItem('ShopID')),
    'time':a
  });

  var config = {
    method: 'post',
    url: BaseURL+'updateordertimeSetting  ',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}

//This is used for to get store details
/* export const getstoreDetailsApi = (a) => {

  const obj = JSON.parse(a)

  var data = qs.stringify({
    'token': JSON.parse(localStorage.getItem('setToken'))
  });
  var config = {
    method: 'get',
    url: BaseURL+'shop_details',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}
 */

// This is used for to get shop_account_details
export const getShopAccountDetailsApi = () => {

  var data = qs.stringify({
    'token': JSON.parse(localStorage.getItem('setToken')),
    'shop_id' : JSON.parse(localStorage.getItem('ShopID')),
  });

  var config = {
    method: 'post',
    url: BaseURL+'updateUserAccount',
    //url: 'https://api.gofrcourier-ecommerce.com/public/api/updateUserAccount',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      //console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
    


  /* var data = qs.stringify({
    'token': JSON.parse(localStorage.getItem('setToken'))
  });
 console.log(data,"token");
  var config = {
    method: 'post',
    //url: BaseURL+'updateUserAccount',
    url : 'https://api.gofrcourier-ecommerce.com/public/api/updateUserAccount',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data : {"token":"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"},
  };
  return axios(config)
    .then(function (response) {
      console.log('get shop details', response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    }); */

  
}

export const postPickupLocation = (data) => {
  var config = {
    method: 'post',
    url: BaseURL+'anotherpick',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}

export const togetPickupLocation = () => {
  var config = {
    method: 'post',
    url: BaseURL+'togetpickuplocation',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}

export const deletePickupLocation = (event) => {
  
  const data1 = new FormData()
  data1.append('shop_id', JSON.parse(localStorage.getItem('ShopID')))
  data1.append('store_locations', JSON.stringify([event]))

  var config = {
    method: 'post',
    url: BaseURL+'deletepickuplocation',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data1
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}

export const getOrderApiDetails = (date) => {
  console.log("ckjdfghjk", date);
  var data = qs.stringify({
    'shop_id': JSON.parse(localStorage.getItem('ShopID')),
    'token': JSON.parse(localStorage.getItem('setToken')),
    'shop_name': JSON.parse(localStorage.getItem('setShopName').replaceAll("%20", "-")),
    'scheduled_date' : date,
  });

  console.log("data", data);

  var config = {
    method: 'post',
    url: BaseURL+'showorders',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
     // console.log(JSON.stringify(response.data));
      return response.data

    })
    .catch(function (error) {
      console.log(error);
    });
}


export const getPlanSubRequest = (date) => {
  
  var data = qs.stringify({
    'shop_id': JSON.parse(localStorage.getItem('ShopID')),
  });

  var config = {
    method: 'post',
    url: BaseURL+'toGetPlanSubRequest',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  return axios(config)
    .then(function (response) {
      //console.log(JSON.stringify(response.data));
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    });
}