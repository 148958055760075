import React, { useEffect, useState } from "react";
import {
  makeStyles,
} from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Footer from "../Components/CommonComponents/Footer";
import { getShopAccountDetails } from "../Store/Actions/auth";
import { useDispatch } from "react-redux";
import {
  getShopAccountDetailsApi,
} from "../Lib/Service/api";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
    borderColor: "green",
  },
  heading: {
    padding: "15px 25px",
    borderBottom: "1px solid rgba(0,0,0,.1)",
    display: "block",
    width: "100%",
    textAlign: "left",
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 30,
  },
  mxauto: {
    margin: "auto",
  },
  Link: {
    textTransform: "uppercase",
    fontSize: "12px",
    color: "#000",
    textDecoration: "none",
    transitions: "all .4s",
    "&:hover": {
      color: "#84C5A3",
      fontWeight: "bold",
    },
  },
  locationtxt: {
    fontSize: "12px",
    fontWeight: "500",
    color: "000",
    textTransform: "uppercase",
  },
  accountContainer: {
    maxWidth: "535px",
    marginLeft: "80px",
    [theme.breakpoints.down("sm", "md")]: {
      marginLeft: "0px",
    },
  },
  anchor_btn: {
    color: "rgb(132, 197, 163)",
    textDecoration: "underline",
    width: "352px",
    display: "flex",
    marginTop: "8px",
    [theme.breakpoints.down("sm", "md")]: {
      width: "100%",
    },
  },
  btn: {
    backgroundColor: "#84C5A3",
    width: "535px",
    display: "flex",
    marginTop: "28px",
    [theme.breakpoints.down("sm", "md")]: {
      width: "100%",
    },
  },
  formwrap: {
    padding: "10px 50px 20px",
    [theme.breakpoints.down("sm", "md")]: {
      padding: "0px 20px 10px",
    },
  },
  margin: {
    margin: "0",
  },
  alertbox: {
    marginBottom: "40px",
  },
}));


export default function CustomizedInputs() {

  const dispatch = useDispatch();
  const classes = useStyles();
  const [active, setActive] = React.useState(true);
  const [currentShopList, setCurrentShopDetails] = useState([]);

  useEffect(() => {
    getCurrentShopDetails();
    dispatch(getShopAccountDetails());
  }, []);

  const getCurrentShopDetails = async () => {
    setActive(true);
    const apiData = await getShopAccountDetailsApi();
    if (apiData.status === false) {
      setCurrentShopDetails([]);
      toast.error(apiData.Result);
      setActive(false);
    } else {
      setCurrentShopDetails(apiData.Result);
      setActive(false);
    }
  };

  return (
    <div>
      <div className={classes.accountContainer}>
        { active ? (
          <CircularProgress />
        ) : (
        <List style={{ marginLeft: "-3%" }}>
          <ListItem>
            <h2 style={{ color: "darkgray" }}>
              Account Profile
            </h2>
          </ListItem>
          <ListItem>
            <div style={{display:"flex"}}>
              <h4 style={{ color: "#000" }}>
                Owner Name :&nbsp;
              </h4>
              <p style={{margin:"19px"}}>
                {currentShopList?.shop?.shop_owner}
              </p>
            </div>
          </ListItem>
          <ListItem>
            <div style={{display:"flex"}}>
              <h4 style={{ color: "#000" }}>
                Email :&nbsp;
              </h4>
              <p style={{margin:"19px"}}>
                {currentShopList?.shop?.customer_email}
              </p>
            </div>
          </ListItem>
          <ListItem>
            <div style={{display:"flex"}}>
              <h4 style={{ color: "#000" }}>
                Store Location :&nbsp;
              </h4>
              <p style={{margin:"19px"}}>
                {currentShopList?.shop?.address1+', '}{(currentShopList?.shop?.address2)? currentShopList?.shop?.address2+', ' : ''}{(currentShopList?.shop?.city)? currentShopList?.shop?.city+', ' : ''}{(currentShopList?.shop?.country_name)? currentShopList?.shop?.country_name+', ' : ''}{(currentShopList?.shop?.zip)? currentShopList?.shop?.zip : ''}{(currentShopList?.shop?.phone)? '-'+currentShopList?.shop?.phone : ''}
              </p>
            </div>
          </ListItem>
          
        </List>
        )}
      </div>
      <Footer />
    </div>
  );
}
