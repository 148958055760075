import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Card, CardContent, Typography, CardActions, Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux'
import { planSubscribe } from '../Store/Actions/auth'
import { userPlan, planUnsubscribe, sendChargeId } from "../Store/Actions/auth"
import CheckIcon from '@material-ui/icons/Check';
import { parseJSON } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import { getPlanSubRequest } from "../Lib/Service/api";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 350,
    width: 250,
  },
  control: {
    padding: theme.spacing(2),
  },
  alertbox: {
    marginLeft: "5%",
    maxWidth: '500px',
    margin: '0 auto 40px',
  }
}));


export default function SpacingGrid() {
 

  const dispatch = useDispatch();
  const redirect = useSelector((state) => state.auth.subscribeData)
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();
  const planData = useSelector((state) => state.auth.planData.Result)
  const subPlan = parseInt(useSelector((state) => state.auth.planData.Result))
  if (window.location.href !== "https://gofrcourier-ecommerce.com/dashboard") {
    // setUrl(window.location.href);
    const sub = JSON.parse(localStorage.getItem('subscribe'))

    const str = window.location.href;
    const url1 = str.split("=");
    // console.log(url1);
    // dispatch(sendChargeId(url1[1],sub.data.plan_id))
  }
  // if(true){
  //   // setUrl(window.location.href);
  //   const sub=JSON.parse(localStorage.getItem('subscribe'))
  //   const str=window.location.href;
  //   const url1= str.split("/");
  //   console.log(url1);
  // }
  // console.log(planData);
  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };

  const [planCheckStatus, setPlanCheckStatus] = useState();
  const [active, setActive] = useState(true);

  useEffect(() => {
    toCheckPlanSubRequest();
  },[]);

  const toCheckPlanSubRequest = async () => {
    const listPlanSubRequest = await getPlanSubRequest();
    setActive(true);
    setPlanCheckStatus(listPlanSubRequest);
  };

  const [response, setResponse] = useState([])

  const [isRender, setIsrender] = useState("")
  // 
  const data = localStorage.getItem('user')
  const Sub = JSON.parse(localStorage.getItem('subscribe'))

  useEffect(() => {
    parseJSON(Sub)
    console.log("Sub", Sub);
  }, [Sub])


  const handleClick = (a) => {
    dispatch(planSubscribe(a))
  }
  const handleUnsubscribe = (a) => {
    const confirmBox = window.confirm(
      "Are you sure you want to unsubscribe from this plan?"
    )
    if (confirmBox === true) {
      dispatch(planUnsubscribe(a));
      setResponse([]);
      setResponse(planData);
    }
  }

  let planCheck = (data) => {
    console.log(Sub != null);
    if (planCheckStatus?.status === true) {
      if (data.planId === parseInt(planCheckStatus?.Result[0].plan_id)) {
        return (<Button style={{ backgroundColor: "#E1C16E", marginTop: "4%", fontWeight: "bold" }} onClick={() => handleUnsubscribe(data)}>Unsubscribe</Button>
        )
    
      }
      else {
        return ( 
           data.length ? 
          <Button style={{ backgroundColor: "#84C5A3", marginTop: "4%", fontWeight: "bold" }} onClick={() => handleClick(data)}>Subscribe</Button> : 
           <Button style={{ backgroundColor: "#dbdbdb", marginTop: "4%", fontWeight: "bold", pointerEvents: "none",
          cursor: "not-allowed"}} >Subscribe</Button>
        )  
      }
    }
    else {
      return (<Button style={{ backgroundColor: "#84C5A3", marginTop: "4%", fontWeight: "bold" }} onClick={() => handleClick(data)}>Subscribe</Button>)
    }
    // if (Sub != null) {
    //   if (data.planId === parseInt(Sub.data.plan_id)) {
    //     return (<Button style={{ backgroundColor: "#E1C16E", marginTop: "4%", fontWeight: "bold" }} onClick={() => handleUnsubscribe(data)}>Unsubscribe</Button>
    //     )
    
    //   }
    //   else {
    //     return ( 
    //        data.length ? 
    //       <Button style={{ backgroundColor: "#84C5A3", marginTop: "4%", fontWeight: "bold" }} onClick={() => handleClick(data)}>Subscribe</Button> : 
    //        <Button style={{ backgroundColor: "#dbdbdb", marginTop: "4%", fontWeight: "bold", pointerEvents: "none",
    //       cursor: "not-allowed"}} >Subscribe</Button>
    //     )  
    //   }
    // }
    // else {
    //   return (<Button style={{ backgroundColor: "#84C5A3", marginTop: "4%", fontWeight: "bold" }} onClick={() => handleClick(data)}>Subscribe</Button>)
    // }
  }

  useEffect(() => {
    dispatch(userPlan(data))
  }, []);

  useEffect(() => {
    setResponse(planData);
  });
  useEffect(() => {
    if (redirect.status) {
      window.location.href = redirect.redirect_url;
    }
  }, [redirect])

  return (
    <div>
      <h1  style={{ textAlign: "left" }}>Pricing details</h1>
      <div className={classes.alertbox}> <Alert severity="info">This page helps to view, select and upgrade all the plans</Alert></div>
      {(response && response.length < 1) ? (
        ""
      ) : (

        <div>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={spacing}>

                {response && response.map((data) =>
                  <Grid style={{ marginLeft: "5%" }} item>
                    <Card className={classes.paper}>
                      <CardContent>

                        <Typography align="left" className={classes.pos} color="textSecondary" gutterBottom>
                          {data.planName}
                        </Typography>

                        <Typography style={{ marginTop: "10px" }} align="left" variant="h5" component="h2">
                          ${data.planCost}/month
                        </Typography>
                        
                        <Typography style={{ marginTop: "25px" }} align="left" variant="body2" component="p">
                          <CheckIcon style={{ color: "#84C5A3" }} /> <div style={{ marginLeft: "15%", marginTop: "-13%" }}> {data.desc[0]}</div>
                        </Typography>

                        <Typography style={{ marginTop: "25px" }} align="left" variant="body2" component="p">
                          <CheckIcon style={{ color: "#84C5A3" }} /><div style={{ marginLeft: "15%", marginTop: "-13%" }}> {data.desc[1]}</div>
                        </Typography>

                        {/* <Typography style={{ marginTop: "15px" }} align="left" variant="body2" component="p">
                          <CheckIcon style={{ color: "#84C5A3" }} /><div style={{ marginLeft: "15%", marginTop: "-13%" }}> {data.desc[2]}</div>
                        </Typography> */}

                      </CardContent>

                    </Card>
                    {/* <Button style={{backgroundColor:"#84C5A3",marginTop:"4%",fontWeight:"bold"}} onClick={()=>handleClick(data)}>Subscribe</Button> */}
                    <React.Fragment style={{ marginLeft: "10%" }}>{planCheck(data)}</React.Fragment>
                  </Grid>
                )}

              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </div>);
}
