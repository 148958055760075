import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import Layout from '../../Routes/Layout'
import Avatar from '@material-ui/core/Avatar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../../Store/Actions/auth'
import Logo from "../../Assets/logo.png"
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import './style.css';
import { getPlanSubRequest } from "../../Lib/Service/api";

const drawerWidth = 240;



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function PersistentDrawerLeft() {

  var to_get_store_name = localStorage.getItem('setShopName');
  if(to_get_store_name){
    var store_shop_name = JSON.parse(localStorage.getItem('setShopName').replaceAll("%20", "-"));
  }else{
    var store_shop_name = 'Store Name';
  }
  
  //const getStoreDetails = useSelector((state) => state.auth.getStoreDetails);
  //console.log(">>>>>>>>>>>>>>>>>>>.", getStoreDetails);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const history = useHistory();

  const handleDashboard = () => {

    history.push("/dashboard")

  }

  const handleManageOrder = () => {

    history.push("/manage-order")

  }
  const handleAccount = () => {

    history.push("/account")

  }
  const handleAccountSetting = () => {

    history.push("/account-setting")

  }
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth.userData);
  const localData = localStorage.getItem('user');
  const handleSubscribe = () => {
    history.push("/subscribe")
  }

  const [active, setActive]=useState(true);
  useEffect(() => {
    toCheckPlanSubRequest();
  },[]);

  const toCheckPlanSubRequest = async () => {
    const listPlanSubRequest = await getPlanSubRequest();
    if(listPlanSubRequest.status){
      setActive(false);
    }else{
      setActive(true);
    }
  }; 
  
/*const Sub = JSON.parse(localStorage.getItem('subscribe') )

 useEffect(()=>{
if(Sub!==null){
  console.log("setactive");
  setActive(false)
}
},[Sub]) */

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

  return (
    <div className={classes.root}>

      {isLoggedIn ? (

        <div>

          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar style={{ background: '#84C5A3' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>

              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem alignItems="center">
                <Avatar id="logo" style={{ marginBottom: "15%" }} src={Logo} className={classes.large} />
    
              </ListItem>
              <ListItem alignItems="center">
                <Avatar alt="Remy Sharp" style={{ marginLeft: "25%", borderRadius: "50%", maxWidth: "80px", minHeight: "80px" }} src="" className={classes.large} />
              </ListItem>
              <ListItem alignItems="center">
                <ListItemText style={{ fontWeight: "bolt", margin: "10%", marginLeft: "25%" }} primary={store_shop_name} />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem button onClick={handleDashboard}>
                <ListItemIcon>
                  <DashboardIcon style={{ color: "#84C5A3" }} />
                </ListItemIcon>
                <ListItemText primary="Instructions" />
              </ListItem>
              <ListItem disabled={active}  button onClick={handleManageOrder}>
                <ListItemIcon>
                  <AssignmentIcon style={{ color: "#84C5A3" }} />
                </ListItemIcon>
                <ListItemText style={{ fontWeight: "bold" }} primary="Manage Orders " />
              </ListItem>

            </List>
            <Divider />
            <List style={{marginBottom:'100px'}}>
              <b>     <ListItem>
                <h3 style={{ marginTop: "-.5%", marginBottom: "-0.5%" }}>Settings</h3>
                {/* <ListItemText style={{fontWeight:"bolt"}} primary="Settings" /> */}
              </ListItem></b>
              <ListItem disabled={active} button onClick={handleAccount}>
                <ListItemIcon>
                  <PersonIcon style={{ color: "#84C5A3" }} />
                </ListItemIcon>
                <ListItemText primary="Account" />
              </ListItem>
              <ListItem disabled={active} button onClick={handleAccountSetting}>
                <ListItemIcon>
                  <SettingsIcon style={{ color: "#84C5A3" }} />
                </ListItemIcon>
                <ListItemText primary="App Settings " />
              </ListItem>
              <ListItem  button onClick={handleSubscribe}>
                <ListItemIcon>
                  <SubscriptionsIcon style={{ color: "#84C5A3" }} />
                </ListItemIcon>
                <ListItemText primary="Subscribe Plan " />
              </ListItem>

            </List>
          {/*   <List style={{position:'fixed', bottom:'0px', left:'0px', background:'#fff'}}>
              <ListItem >
                <ListItemIcon>

                  <img src="chat.svg" />
                </ListItemIcon>

                <h3 >Chat support</h3>
              </ListItem>

            </List> */}
          </Drawer>
        </div>
      ) : (
        <Drawer
        className={classes.drawer}
        variant="persistent"
   
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        </Drawer>
              )}

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Layout />

      </main>
    </div>
  );
}
