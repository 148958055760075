import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute (props) {
	return (
		<Route path={props.path} exact render={() => {
			return props.isLogin ? <div className="mainContent">{props.component}</div> : <Redirect to="/dashboard" />
		}} ></Route>
	);
}

