
import { registerNewUser,loginUser,logoutUser,sendTimeApi, getShopAccountDetailsApi, userPlanApi, getstoreDetailsApi,sendChargeApi,subscribePlan,unsubscribePlan,getOrderApi,pushOrderApi,orderDetailsApi, getordertimesettingsApi } from "../../Lib/Service/api"
import { toast } from 'react-toastify'; 


export const setOrderData = (data) => {
	return {
		type: "ORDERDATA",
		data,
	}
};

export const setAppSetting = (data) => {
	return {
		type: "APPSETTING",
		data,
	}
};

export const setShopAccountDetails = (data) => {
	return {
		type: "SHOPACCOUNTDETAILS",
		data,
	}
};

export const setLogout = () => {
	return {
		type: "LOGOUT",
	}
};
export const setRegister = () => {
	return {
		type: "REGISTER",
	}
};
export const setLogin = (data) => {
	return {
		type: "LOGIN",
		data,
	}
};
export const setToken = (data) => {
	return {
		type: "SETTOKEN",
		data,
	}
};
export const setShopID = (data) => {
	return {
		type: "SHOPID",
		data,
	}
};
export const setShopName = (data) => {
	return {
		type: "SHOPNAME",
		data,
	}
};

export const setLoginAppdata = (data) => {
	return {
		type: "LOGIN",
		data,
	}
};


export const setPlanID = (data) => {
	return {
		type: "SETPLAN",
		data
	}
};
export const clearRegister = () => {
	return {
		type: "CLEAR_REG",
	
	}
};
export const setorderDetails = (data) => {
	return {
		type: "SETORDER",
	data,
	}
};
export const userLogin = (data) => async (dispatch) => {
	console.log("login Action");
	try {
		const response = await loginUser(data)
		console.log(response);
		if(response.status){
			toast.success("Login Successful");
			localStorage.setItem('user', JSON.stringify(response));
			dispatch(setLogin(response));
		}
		else{
			toast.error(response.Message);
		}
	}catch (e) {

	}
};
export const pushOrder = (data) => async (dispatch) => {

	try {
		console.log("payloadData", data);
		console.log("window.location.reload();", window.location.href);
		const response = await pushOrderApi(data)
		console.log("resultResponce",response);
		console.log("resultResponce",response.status);
		
		if(response.status){
			
			toast.success("Order Initiated");
			setTimeout(() => {
				window.location.replace(window.location.href);
			}, 2000);   

		// localStorage.setItem('user', JSON.stringify(response));
		// dispatch(setLogin(response));
		}
		else{
			//window.location.reload();
			toast.error(response.Message);
		}
	}

	catch (e) {

	}
};
export const orderDetailAciton = (data) => async (dispatch) => {

	try {
		const response = await orderDetailsApi(data)
		console.log(response);
		if(response.status){
			toast.success("Order Details");
		// localStorage.setItem('user', JSON.stringify(response));
		dispatch(setorderDetails(response));
		}
		else{
			toast.error(response.Message);
		}
	}

	catch (e) {

	}
};

export const userLoginAppData = (data) => async (dispatch) => {
	console.log("login Action");
	try {
		// const response = await loginUser(data)
		// console.log(data);
		//  const obj = JSON.parse(a)
		dispatch(setLoginAppdata(data));
		
	}

	catch (e) {

	}
};


export const userLogout = (data) => async (dispatch) => {

	try {
		dispatch(setLogout());

		const response = await logoutUser(data)
// console.log(response);
		if(response.status){
			console.log(response.Status);
			localStorage.removeItem("user");		
		dispatch(setLogout());
		toast.success("Logout Successful");
		

		}else{
			toast.error("Logout Failed");
			console.log(response.Status);
			localStorage.removeItem("user");

		}
	} catch (e) {

	}
};



export const register = (data) => async (dispatch) => {
	console.log("call action",data);
	try {
		// var res = data.data["search_text"].substring(0, 1);

		const response = await registerNewUser(data)
		console.log(response);
		if (response.status) {
			toast.success("Account Created");
			dispatch(setRegister());
			// window.location.replace("http://localhost:3000/login")
		}
		if(!response.status){
		toast.error(response.Message);
	}

	} catch (e) {
		// dispatch(userRegisterFailed());
		// 
	}
};
export const setPlan = (data) => {
	return {
		type: "USERPLAN",
        data,
	}
};

/* export const set_store_details = (data) => {
	return {
		type: "GET_STORE_DETAILS",
        data,
	}
}; */


export const userPlan = (data) => async (dispatch) => {
	console.log("login Action");
	try {
		const response = await userPlanApi(data)
		console.log("plan Data",response);
		if(response.data.status){
            console.log("ok status");
		dispatch(setPlan(response.data));
		}
		else{
			toast.error(response.Message);
		}
	}

	catch (e) {

	}
};

/* export const get_store_details = (data) => async (dispatch) => {
	console.log("login Action");
	try {
		const response = await getstoreDetailsApi(data)
		console.log("get_store_details Data",response);
		if(response.data.status){
            console.log("ok status");
		dispatch(set_store_details(response.data));
		}
		else{
			toast.error(response.Message);
		}
	}

	catch (e) {

	}
}; */

export const setSubscribe = (data) => {
	return {
		type: "SUBSCRIBE",
        data,
	}
};
export const planSubscribe = (data) => async (dispatch) => {
	console.log("login Action",data);
	try {
		const response = await subscribePlan(data)
		console.log("subscribe Data",response);
		if(response.data.status){
			localStorage.setItem('subscribe', JSON.stringify(response));
			dispatch(setSubscribe(response.data));
		}
		else{
			toast.error(response.Message);
		}
	}

	catch (e) {

	}
};
export const sendChargeId = (data,data2) => async (dispatch) => {

	try {
		const response = await sendChargeApi(data,data2)
console.log(response);
if(response.status){
	dispatch(setPlanID(response));
}
		


		
	} catch (e) {

	}
};




export const setUnsubscribe = (data) => {
	return {
		type: "UNSUBSCRIBE",
       data,
	}
};
export const planUnsubscribe = (data) => async (dispatch) => {
	console.log("login Action",data);
	try {
		const response = await unsubscribePlan(data)
		console.log("subscribe Data",response);
		if(response.data.status === true){
			dispatch(setUnsubscribe(response.data));
			window.location.href = "https://gofrcourier-ecommerce.com/dashboard";
		}
		else{
			toast.error(response.Message);
		}
	}

	catch (e) {

	}
};
export const getOrderData = () => async (dispatch) => {
console.log("order list action");
	try {
		const response = await getOrderApi()
console.log(response,"kj");
		

if (response.status) {

	dispatch(setOrderData(response));
}
		
	} catch (e) {

	}
};


export const getFilterOrderData = () => async (dispatch) => {
	console.log("order list action");
		try {
			const response = await getOrderApi()
	console.log(response);
			
	
	if (response.status) {
		dispatch(setOrderData(response));
	}
			
		} catch (e) {
	
		}
	};

export const appSettingData = (data) => async (dispatch) => {
	try {
		const response = await getordertimesettingsApi(data)
		console.log(response);
		if(response.status){
	
		// localStorage.setItem('user', JSON.stringify(response));
		dispatch(setAppSetting(response.Result));
		}
		else{
			toast.error(response.Message);
		}
	}
	
	catch (e) {

	}
};

//To update shop details
export const getShopAccountDetails = () => async (dispatch) => {
	try {
		const response = await getShopAccountDetailsApi()
		if(response.status){
			dispatch(setShopAccountDetails(response.Result));
		}
		else{
			toast.error(response.Message);
		}
	}catch(e) {
       console.log(e,"err");
	}
};


export const sendTime = (data) => async (dispatch) => {
	try {
		const response = await sendTimeApi(data)
		console.log(response, "=============");
		if(response.status){
			dispatch(setAppSetting(response.Result));
			toast.success("Order time setting update");
		}
		else{
			toast.error(response.Message);
		}
	}

	catch (e) {

	}
};


export const scheduleDate = (data) => {
	return {
		type: "SCHEDULEDATE",
        data,
	}
};

